import React, { Component } from 'react';
import Close from '../../components/assets/Close.js'

import './NevermindButton.scss'

class NevermindButton extends Component {
    render () {
        var visibility = "inactive";

        if (this.props.formVisibility) {
        visibility = "active";
        }
        return (
                <button
                className= {"nevermind--" + visibility}
                onMouseDown={this.props.handleMouseDown}> <Close />        
                </button>
        );
    }
}

export default NevermindButton