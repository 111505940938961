import React from 'react';

export default ({className}) => (
        <svg
            className={className}
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g 
                stroke="none"
                strokeWidth="1" 
                fill="#ff0000" 
                fillRule="evenodd">
                <g 
                    transform="translate(-211.000000, -129.000000)" 
                    fill="#ff0000" 
                    fillRule="nonzero"
                >
                    <path 
                        d="M221,129 C215.47,129 211,133.47 211,139 C211,144.53 215.47,149 221,149 C226.53,149 231,144.53 231,139 C231,133.47 226.53,129 221,129 Z M226,142.59 L224.59,144 L221,140.41 L217.41,144 L216,142.59 L219.59,139 L216,135.41 L217.41,134 L221,137.59 L224.59,134 L226,135.41 L222.41,139 L226,142.59 Z"></path>
                </g>
            </g>
        </svg>
);