import React, { Component } from 'react';
import firebase from '../../firebase';
import Avatar from 'react-avatar';

import './Comments.scss';

class Comments extends Component {
    constructor() {
      super();
      this.state = {
        guestbookComment: '',
        username: '',
        items: [],
        visible: false
      }
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleMouseDown = this.handleMouseDown.bind(this);
      this.toggleForm = this.toggleForm.bind(this);
    }
    handleMouseDown(e) {
      this.toggleForm();
   
      console.log("clicked");
      e.stopPropagation();
    }
  
    toggleForm() {
      this.setState({
          visible: !this.state.visible
      });
    }

    handleChange(e) {
      this.setState({
        [e.target.name]: e.target.value
      });
    }
    handleSubmit(e) {
      e.preventDefault();
      const itemsRef = firebase.database().ref('items');
      const item = {
        title: this.state.guestbookComment,
        user: this.state.username
      }
      itemsRef.push(item);
      this.setState({
        guestbookComment: '',
        username: ''
      });
    }
    componentDidMount() {
      const itemsRef = firebase.database().ref('items');
      itemsRef.on('value', (snapshot) => {
        let items = snapshot.val();
        let newState = [];
        for (let item in items) {
          newState.push({
            id: item,
            title: items[item].title,
            user: items[item].user
          });
        }
        this.setState({
          items: newState
        });
      });
    }
    removeItem(itemId) {
      const itemRef = firebase.database().ref(`/items/${itemId}`);
      itemRef.remove();
    }
      render() {
        var visibility = "--test";

        if (this.props.formVisibility) {
        visibility = "--show";
        }
        console.log('printing')
          return (
            <div className="comments--container">
                <section className="comments">
                    <div className={"wrapper" + visibility}>              
                        <div className="comments--title">
                            <span className="comments--title__inner">Some kind words</span>
                        </div>
                        <ul className="comments--list ">
                          {this.state.items.reverse().map((item) => {
                            return (
                              <li key={item.id}>
                                <div className="comment--card">
                                  <p className="comment--name">
                                    <Avatar
                                        className="comment--name__avatar"
                                        name={item.user}
                                        round="20px"
                                        maxInitials="2"
                                        size="24px"
                                        textSizeRatio="2"
                                        color="#357EDD"
                                    />
                                    {item.user}
                                  </p>
                                  <p class="comment--entry">{item.title}</p>
                                    {/*  
                                    REMOVE COMMENT BUTTON
                                    <button onClick={() => this.removeItem(item.id)}>Remove Item</button>
                                    */}
                                </div>
                              </li>
                            )
                            })}
                        </ul>
                    </div>
                </section>
            </div>
          )
      }
}

export default Comments;