 // src/firebase.js
 import firebase from 'firebase'
  const config = {
    apiKey: "AIzaSyBNd6RFibpXJHvlHLlqCzughnl3EdQArX4",
    authDomain: "guestbook-959f1.firebaseapp.com",
    databaseURL: "https://guestbook-959f1.firebaseio.com",
    projectId: "guestbook-959f1",
    storageBucket: "",
    messagingSenderId: "959476864288"
  };
  firebase.initializeApp(config);
  export const provider = new firebase.auth.GoogleAuthProvider();
  export const auth = firebase.auth();
  export default firebase;