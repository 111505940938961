import React, { Component } from 'react';
import FormInner from './FormInner.js'
import ToggleButton from './ToggleButton.js'
import NevermindButton from './NevermindButton'
import './Form.scss';

class Form extends Component {
    constructor() {
        super();
        this.state = {
            visible: false
        }
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.toggleForm = this.toggleForm.bind(this);
    }

    handleMouseDown(e) {
        this.toggleForm();
     
        console.log("clicked");
        e.stopPropagation();
      }
    
      toggleForm() {
        this.setState({
            visible: !this.state.visible
        });
      }
    render() {
        return (
            <React.Fragment>                
                <NevermindButton
                    handleMouseDown={this.handleMouseDown}
                    formVisibility={this.state.visible}
                    type="reset">NVMND</NevermindButton>
                <FormInner 
                formVisibility={this.state.visible}
                />

                <ToggleButton 
                    handleMouseDown={this.handleMouseDown}
                    formVisibility={this.state.visible}
                />
            </React.Fragment>
        );
    
        }
    }
export default Form;