import React, { Component } from 'react';

import './ToggleButton.scss'

class ToggleButton extends Component {
    render () {
        var visibility = "visible";

        if (this.props.formVisibility) {
        visibility = "hidden";
        }
        return (
            <div className= {visibility}>
                <button id="mobile-button"
                onMouseDown={this.props.handleMouseDown}>Sign The Book</button>
            </div>
        );
    }
}

export default ToggleButton