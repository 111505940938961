import React, { Component } from 'react';

import './Header.scss';

class Header extends Component {
    render() {
        return(
                <div id='warped'>
                    <span className='w0'>R</span>
                    <span className='w1'>o</span>
                    <span className='w2'>b</span>
                    <span className='w3'>y</span>
                    <span className='w4'>'</span>
                    <span className='w5'>s</span>
                    <span className='w6'> </span>
                    <span className='w7'>G</span>
                    <span className='w8'>u</span>
                    <span className='w9'>e</span>
                    <span className='w10'>s</span>
                    <span className='w11'>t</span>
                    <span className='w12'>b</span>
                    <span className='w13'>o</span>
                    <span className='w14'>o</span>
                    <span className='w15'>k</span>
                </div>
        )
    }
};

export default Header;