import React, { Component } from 'react';
import firebase from '../../firebase';
import './Form.scss';

class FormInner extends Component {
    constructor() {
        super();
        this.state = {
            guestbookComment: '',
            username: '',
            items: [],
            visible: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.toggleForm = this.toggleForm.bind(this);
    }

    handleMouseDown(e) {
        this.toggleForm();
     
        console.log("clicked");
        e.stopPropagation();
      }
    
      toggleForm() {
        this.setState({
            visible: !this.state.visible
        });
      }

    handleChange(e) {
        this.setState({
          [e.target.name]: e.target.value
        });
      }
    handleSubmit(e) {
    e.preventDefault();
    const itemsRef = firebase.database().ref('items');
    const item = {
        title: this.state.guestbookComment,
        user: this.state.username
    }
    itemsRef.push(item);
    this.setState({
        guestbookComment: '',
        username: ''
    });
    }

    isFormValid = () => {
        const {guestbookComment, username} = this.state
      
        return guestbookComment && username
      }
    render(){
        const { guestbookComment, username } = this.state;
        const enabled =
              guestbookComment.length > 0 &&
              username.length > 0;

        var visibility = "hide";

        if (this.props.formVisibility) {
        visibility = "show";
        }
        return(
            <section 
                className={ "form--" + visibility}
                onMouseDown={this.props.handleMouseDown}
                >
                    <div className="form--inner">
                        <form onSubmit={this.handleSubmit}>
                            <input type="text" name="username" placeholder="Name" onChange={this.handleChange} value={this.state.username} required />
                            <textarea name="guestbookComment" placeholder="Comment" onChange={this.handleChange} value={this.state.guestbookComment} required />
                            <div className="form--inner__buttons">

                                <button type="submit" disabled={!enabled}>
                                Leave Comment
                                </button>
                            </div>
                        </form>
                    </div>
                </section>
        );
    }
}

export default FormInner