import React, { Component } from 'react';

import Form from './components/form/Form';
import Comments from './components/comments/Comments';
import Header from './components/header/Header'
import './App.scss';

class App extends Component {
  render() {
    return (
      <div className="app">
        <React.Fragment>
          <Header />
          <Form />
          <Comments />
        </React.Fragment>
      </div>
    );
  }
}
export default App;